import { isSuccess } from "common/api-status/utils";
import { services } from "common/orders/services";
import { actionTypes as sessionManagementTypes } from "common/session-management/ducks";
import { put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./ducks";
export const sideBarSagas = [
  takeLatest(
    [
      isSuccess(sessionManagementTypes.AUTHENTICATE_USER),
      isSuccess(sessionManagementTypes.VERIFY_USER)
    ],
    shouldShowOrdersIcon
  )
];

function* shouldShowOrdersIcon(action) {
  try {
    let customerId = yield select(state => state.root.userInfo.userid);
    let { totalCount } = yield services.getOrders(0, {
      "page-no": 1,
      "no-of-records": 1,
      "customer-id": customerId
    });
    if (totalCount >= 10) {
      yield put(actions.showAllOrders());
    }
  } catch (error) {}
}
